#CookieReportsAccordion {
    width: 102%;
    padding-top: 10px;
}
#CookieReportsAccordion #CookieReportsAccordionSections .CookieReportsAccordionHeading {
    outline: none;
}
#CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionCount {
    min-width: 25px;
}
#CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle {
    padding: 0;
}
#CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
    font-size: 19px;
    font-family: "TFArrow-Medium";
}
#CookieReportsAccordion #CookieReportsAccordionSections dd .wscrSectionDescription {
    font-family: "TFArrow-Medium";
    padding: 11px 14px;
    color: #fff;
    font-size: 14px;
}
#CookieReportsAccordion #CookieReportsAccordionSections dd .wscrSectionDescription p {
    font-size: 14px;
    font-family: "TFArrow-Medium";
    color: #fff;
    letter-spacing: 1px;
    line-height: 1.5;
}
#CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table th {
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    font-family: "TFArrow-Medium";
    text-transform: uppercase;
}
#CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
    font-family: "TFArrow-Medium";
    font-size: 14px;
    letter-spacing: 1px;
}
#CookieReportsAccordion #wscrAccordionInjectContent span {
    color: #abb8bd!important;
    font-size: 12px!important;
}
.wscrCompanyPrivacy li a{
    font-family: "TFArrow-Medium" !important;
}

/* Tablet */
@media (max-width:840px)  { 
    #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
        font-size: 16px;
        letter-spacing: 1px;
    }
    #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table th {
        font-size: 12px;
    }
    
    #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
        font-size: 12px;
    }
    #CookieReportsAccordion a{
        font-size: 12px;
    }
}



/* Mobile */
@media (max-width:480px)  { 
    #CookieReportsAccordion #CookieReportsAccordionSections .wscrSectionTitle .wscrSectionTitleText {
        font-size: 16px;
        letter-spacing: 1px;
    }
    #CookieReportsAccordion #CookieReportsAccordionSections dd .CookieReportsAccordionCookies table td {
        padding: 0px;
        font-size: 9px;
        text-align: left;
    }
    #CookieReportsAccordion #wscrAccordionInjectContent span {
        font-size: 10px!important;
        padding: 4px 10px;
        text-align: left;
        width: 30%;
    }
    #CookieReportsAccordion a{
        font-size: 12px;
    }
}

